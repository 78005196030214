
import { Assets, Context, DisplayObject } from '@icepanel/app-canvas'
import { DiagramContent, Flow, FlowStep, ModelConnection, ModelConnectionTechnology, ModelObject, ModelObjectTechnology, ModelStatus, modelStatuses, Tag, TagGroup, TagGroupIcon, Team } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import getPixi from '@/helpers/pixi'
import { iconUrlForTheme } from '@/helpers/theme'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { ModelModule } from '@/modules/model/store'
import { TagModule } from '@/modules/tag/store'
import { TeamModule } from '@/modules/team/store'

import { RenderModule } from '../store'

@Component({
  name: 'RenderDiagram'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  renderModule = getModule(RenderModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)

  app = getPixi()

  view!: Context.Diagram.View

  objects: Record<string, ModelObject> = {}
  connections: Record<string, ModelConnection> = {}

  ready = false
  renderId: string | null = null
  image: string | null = null
  error: string | null = null

  get diagramContents () {
    return Object.values(this.diagramModule.diagramContents)
  }

  get flows () {
    return Object.values(this.flowModule.flows).map(o => ({
      ...o,
      diagramContent: this.diagramModule.diagramContents[o.diagramId]
    }))
  }

  get renderOptions () {
    return typeof this.$route.query.render_options === 'string' ? JSON.parse(this.$route.query.render_options) : {}
  }

  get currentFlowPathIds () {
    return this.$queryArray('flow_path')
  }

  get overlayGroupId () {
    return this.$queryValue('overlay_group')
  }

  get overlayIdsPinned () {
    return this.$queryArray('overlay_pin')
  }

  get overlayIdsHidden () {
    return this.$queryArray('overlay_hide')
  }

  get overlayIdsFocused () {
    return this.$queryArray('overlay_focus')
  }

  get overlayItems () {
    return [
      ...Object.values({
        ...this.tagModule.tags,
        ...this.modelModule.technologies
      }),
      ...this.teamModule.teams
    ].reduce<Record<string, Tag | ModelObjectTechnology | ModelConnectionTechnology | ModelStatus | Team>>((p, c) => ({
      ...p,
      ['handleId' in c ? c.handleId : c.id]: c
    }), {
      'status-deprecated': modelStatuses.deprecated,
      'status-future': modelStatuses.future,
      'status-live': modelStatuses.live,
      'status-removed': modelStatuses.removed
    })
  }

  get overlayPinned () {
    return this.overlayIdsPinned.map(o => this.overlayItems[o]).filter(o => o)
  }

  get overlayHidden () {
    return this.overlayIdsHidden.map(o => this.overlayItems[o]).filter(o => o)
  }

  get overlayFocused () {
    return this.overlayIdsFocused.map(o => this.overlayItems[o]).filter(o => o)
  }

  async mounted () {
    try {
      await this.editorModule.loadResources()

      await this.renderModule.loadDomainsFromSession()
      await this.renderModule.loadModelFromSession()
      await this.renderModule.loadDiagramContentsFromSession()
      await this.renderModule.loadTagsFromSession()
      await this.renderModule.loadFlowsFromSession()
      await this.renderModule.loadTeamsFromSession()

      const iconUrls = [...new Set(
        Object
          .values(this.modelModule.objects)
          .map(o => o.icon && iconUrlForTheme(o.icon))
          .filter((o): o is string => !!o)
      )]
      iconUrls.forEach(o => Assets.add({
        alias: o,
        src: o
      }))
      await Assets.load(iconUrls)

      this.view = new Context.Diagram.View(this.app, {
        comments: {},
        commit: 0,
        connections: {},
        createdAt: '',
        createdBy: 'user',
        createdById: '',
        groupId: '',
        handleId: '',
        id: '',
        landscapeId: '',
        modelId: '',
        name: '',
        objects: {},
        status: 'current',
        tasksProposed: [],
        type: 'context-diagram',
        updatedAt: '',
        updatedBy: 'user',
        updatedById: '',
        version: 0,
        versionId: ''
      }, {
        focusIds: this.overlayFocused.map(o => o.id),
        hideIds: this.overlayHidden.map(o => o.id),
        iconUrl: env.ICON_URL,
        permission: 'read'
      }, {
        getModelObjects: () => this.objects
      } as any)

      this.ready = true
    } catch (err: any) {
      this.error = err.stack
      throw err
    }
  }

  destroyed () {
    this.view.destroy()
    this.app.destroy()
  }

  async renderDiagram (diagramContent: DiagramContent, flow?: Flow) {
    try {
      this.image = null
      this.error = null
      this.renderId = flow?.id || diagramContent.id

      this.view.diagram.setModel(diagramContent)

      this.diagramModule.setDiagramContentStagedId(diagramContent.status === 'draft' ? diagramContent.id : null)

      const objects = this.modelModule.objects
      this.objects = Object.values(objects).reduce<Record<string, ModelObject>>((p, c) => {
        const parents = objects[c.id]?.parentIds.map(o => objects[o]).filter(o => o) ?? []

        const external = c.external || parents.some(o => o.external)

        const statusLockParent = [c, ...parents].reverse().find(o => o.status === 'future' || o.status === 'deprecated' || o.status === 'removed')
        const status = statusLockParent ? statusLockParent.status : c.status

        return {
          ...p,
          [c.id]: {
            ...c,
            external,
            status
          }
        }
      }, {})

      this.connections = Object.values(this.modelModule.connections).reduce<Record<string, ModelConnection>>((p, c) => {
        const originModelObjectParents = this.objects[c.originId]?.parentIds.map(o => this.objects[o]) ?? []
        const targetModelObjectParents = this.objects[c.targetId]?.parentIds.map(o => this.objects[o]) ?? []

        const originStatusLockParent = [this.objects[c.originId], ...originModelObjectParents].filter(o => o).reverse().find(o => o.status === 'future' || o.status === 'deprecated' || o.status === 'removed')
        const targetStatusLockParent = [this.objects[c.targetId], ...targetModelObjectParents].filter(o => o).reverse().find(o => o.status === 'future' || o.status === 'deprecated' || o.status === 'removed')

        if (originStatusLockParent?.status === 'removed' || targetStatusLockParent?.status === 'removed') {
          c.status = 'removed'
        } else if (originStatusLockParent?.status === 'deprecated' || targetStatusLockParent?.status === 'deprecated') {
          c.status = 'deprecated'
        } else if (originStatusLockParent?.status === 'future' || targetStatusLockParent?.status === 'future') {
          c.status = 'future'
        }

        return {
          ...p,
          [c.id]: c
        }
      }, {})

      const diagramModel = this.modelModule.objects[diagramContent.modelId]
      Object.values(diagramContent.objects).forEach(o => {
        const model = this.objects[o.modelId]
        if (model) {
          const domain = model.domainId !== diagramModel.domainId ? this.domainModule.domains[model.domainId] : null

          let obj: Context.Diagram.Obj.Group | Context.Diagram.Obj.Area | Context.Diagram.Obj.Actor | Context.Diagram.Obj.App | Context.Diagram.Obj.Comp | Context.Diagram.Obj.System | Context.Diagram.Obj.Store
          if (o.shape === 'area') {
            switch (o.type) {
              case 'group': {
                obj = new Context.Diagram.Obj.Group(this.view, o.id)
                break
              }
              default: {
                obj = new Context.Diagram.Obj.Area(this.view, o.id)
                break
              }
            }
          } else {
            switch (o.type) {
              case 'actor': {
                obj = new Context.Diagram.Obj.Actor(this.view, o.id)
                break
              }
              case 'app': {
                obj = new Context.Diagram.Obj.App(this.view, o.id)
                break
              }
              case 'component': {
                obj = new Context.Diagram.Obj.Comp(this.view, o.id)
                break
              }
              case 'system': {
                obj = new Context.Diagram.Obj.System(this.view, o.id)
                break
              }
              case 'store': {
                obj = new Context.Diagram.Obj.Store(this.view, o.id)
                break
              }
              default: {
                throw new Error(`Failed to create ${o.type}`)
              }
            }
          }
          obj.setModel(model)
          obj.setStore(o)
          if ('setStyle' in obj) {
            obj.setStyle({
              domain: domain?.name,
              inScope: true
            })
          }
          this.view.objects[obj.id] = obj
        }
      })

      Object.values(diagramContent.connections).forEach(o => {
        const model = o.modelId ? this.connections[o.modelId] : null
        const origin = o.originId ? this.view.objects[o.originId] : undefined
        const target = o.targetId ? this.view.objects[o.targetId] : undefined
        if (origin && target && (model === null || model)) {
          const con = new Context.Diagram.Obj.Connection(this.view, o.id)
          con.setModel(model)
          con.setStore(o)
          this.view.connections[con.id] = con
        }
      })

      Object.values(this.view.objects).forEach(o => {
        const pinned = this.overlayPinned.filter(t => `status-${o.model.status}` === t.id || o.model.tagIds.includes(t.id) || !!o.model.technologies[t.id] || o.model.teamIds.includes(t.id))
        let pinnedIcon: TagGroupIcon | null = null

        if (this.overlayGroupId) {
          const tagGroup = Object.values(this.tagModule.tagGroups).find(o => o.handleId === this.overlayGroupId)
          pinnedIcon = tagGroup?.icon || null
        }

        o.init()
        o.updateStyle()
        if ('setPinned' in o && pinned.length) {
          o.setPinned(pinned, pinnedIcon)
        }

        this.view.scene.addChild(o as DisplayObject)
      })

      Object.values(this.view.connections).forEach(o => {
        const pinned = this.overlayPinned.filter(t => `status-${o.model?.status}` === t.id || !!o.model?.technologies[t.id] || o.model?.tagIds.includes(t.id))

        let tagGroup: TagGroup | null = null

        if (this.overlayGroupId) {
          tagGroup = Object.values(this.tagModule.tagGroups).find(o => o.handleId === this.overlayGroupId) || null
        }

        o.init()
        o.updateStyle()
        o.setOverlay(tagGroup, null, pinned)
        this.view.scene.addChild(o as DisplayObject)
      })

      if (flow) {
        this.view.flow.setModel({
          ...flow,
          steps: Object
            .values(flow.steps)
            .filter(o => !o.pathId || this.currentFlowPathIds.includes(o.pathId))
            .reduce<Record<string, FlowStep>>((p, c) => ({
              ...p,
              [c.id]: c
            }), {})
        })
      } else {
        this.view.flow.setModel(null)
      }

      const { data } = await this.view.export(this.renderOptions)

      this.image = data

      Object.values(this.view.objects).forEach(o => {
        this.view.scene.removeChild(o as DisplayObject)
        delete this.view.objects[o.id]
        o.destroy()
      })

      Object.values(this.view.connections).forEach(o => {
        this.view.scene.removeChild(o as DisplayObject)
        delete this.view.connections[o.id]
        o.destroy()
      })
    } catch (err: any) {
      console.error(err)
      this.error = err.stack
    }
  }
}
